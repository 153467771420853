
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Routers",
  "subtitle": "Routers you manage",
  "activateNewRouter": "Activate a new Privacy Hero",
  "subscribe": "Subscribe",
  "updatePaymentMethod": "Update Payment Method",
  "updateSubscription": "Update Plan",
  "updateDisabled": "Please wait until your subscription has less than one week of trial time remaining before performing updates",
  "cancelSubscription": "Cancel Subscription",
  "renewSubscription": "Renew Subscription",
  "manageSubscriptions": "Manage Subscriptions",
  "online": "Online",
  "offline": "Offline",
  "activated": "Activated",
  "type": "Type",
  "version": "Version",
  "beta": "Use beta firmware",
  "learnMore": "Learn more",
  "plan": "Plan",
  "trialing": "trialing",
  "free": "Free",
  "renewMonthly": "Renews monthly on",
  "renewYearly": "Renews yearly on",
  "cancel": "Cancels on",
  "change": "Changes to",
  "updateDisabledVerbose": "You are currently still within your initial trial period. Subscriptions can only be updated inside the web app once you are within one week of your trial end date.\n\n You may contact support to make changes to your account status and plan at any time.",
  "betaModalTitle": "Beta",
  "betaModalEnableText": "Please confirm that you want to enable beta releases on router:",
  "betaModalDisableText": "Please confirm that you want to disable beta releases on router:",
  "betaModalRestartRequired": "This change will take effect the next time your router restarts",
  "cancelModalTitle": "Cancel Subscription",
  "cancelFormText": "Your plan will be canceled, but is still available until the end of your billing period. If you change your mind, you can renew your subscription.",
  "cancelReasonTitle": "Reason For Cancellation",
  "cancelTextTitle": "Details",
  "cancelModalDismiss": "Go Back",
  "cancelModalSubmit": "Cancel Subscription"
}
