import http from "axios";
import { ACCOUNT_MESSAGE_ROUTE } from "../constants";

interface MessageResponse {
  string_name: string;
}

export function getAccountMessage() {
  return http.get<MessageResponse>(ACCOUNT_MESSAGE_ROUTE);
}
