export const LOGIN_ROUTE = "/v1/auth/login";
export const RESET_PASSWORD_ROUTE = "/v1/auth/confirmcustomer";

export const CHANGE_CONFIGURATION_SERVICE_ROUTE = "/v1/adapter/cfg/service";
export const FIND_UNCLAIMED_ADAPTERS_ROUTE = "/v2/adapter/find";

export const CLAIM_ADAPTER_ROUTE = "/v1/adapter/claim";

export const GET_DEVICES_ROUTE = "/v1/device/list";
export const DEVICE_SETTING_URL = "/v1/device/setting";
export const DEVICE_USAGE_URL = "/v1/device/usage";

export const GET_PROFILE_LIST_ROUTE = "/v1/profile/list";
export const DELETE_PROFILE_ROUTE = "/v1/profile/delete";
export const SET_PROFILE_SETTINGS_ROUTE = "/v1/profile/cfg/setting";
export const GET_PROFILE_ICONS_ROUTE = "/v1/profile/icons";

export const SET_BEDTIME_ROUTE = "/v1/profile/cfg/bedtimes";
export const SET_BEDTIME_DELAY_ROUTE = "/v1/profile/cfg/bedtime-delay";

export const GET_STREAMING_CONFIG = "/v1/streaming/cfg";
export const GET_STREAMING_SERVICES = "/v1/streaming/services";

export const GET_VPN_PROVIDERS = "/v1/adapter/cfg/vpn/services";
export const SET_VPN_PROVIDER = "/v1/adapter/cfg/vpn";

export const ADAPTER_RESET_URL = "/v1/adapter/reset";
export const BETA_ROUTE = "/v2/adapter/cfg/beta";

export const STRIPE_CONFIG_ROUTE = "/v1/subscription/config";
export const LIST_SUBSCRIPTIONS_ROUTE = "/v1/subscription/list-subscriptions";
export const LIST_CODES_ROUTE = "/v1/subscription/list-codes";
export const LIST_INVOICES_ROUTE = "/v1/subscription/list-invoices";
export const APPLY_REDEMPTION_CODE_ROUTE =
  "/v1/subscription/apply-redemption-code";
export const CLAIM_CODE_ROUTE = "/v1/subscription/claim-code";
export const CREATE_CHECKOUT_SESSION_SUBSCRIPTION_ROUTE =
  "/v1/subscription/create-checkout-session-subscription";
export const CREATE_CHECKOUT_SESSION_ONE_TIME_PAYMENT_ROUTE =
  "/v1/subscription/create-checkout-session-one-time-payment";
export const CREATE_CHECKOUT_SESSION_PAYMENT_METHOD_UPDATE_ROUTE =
  "/v1/subscription/create-checkout-session-payment-method-update";
export const CREATE_CUSTOMER_PORTAL_SESSION_ROUTE =
  "/v1/subscription/create-customer-portal-session";
export const CREATE_CUSTOMER_PORTAL_SESSION_PAYMENT_METHOD_UPDATE_ROUTE =
  "/v1/subscription/create-customer-portal-session-payment-method-update";
export const CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_CANCEL_ROUTE =
  "/v1/subscription/create-customer-portal-session-subscription-cancel";
export const CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_ROUTE =
  "/v1/subscription/create-customer-portal-session-subscription-update";
export const CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_CONFIRM_ROUTE =
  "/v1/subscription/create-customer-portal-session-subscription-update-confirm";
export const SESSION_STATUS_ROUTE = "/v1/subscription/session-status";
export const CANCEL_SUBSCRIPTION_ROUTE = "/v1/subscription/cancel-subscription";
export const RENEW_SUBSCRIPTION_ROUTE = "/v1/subscription/renew-subscription";
export const CHANGE_PLAN_ROUTE = "/v1/subscription/change-plan";

export const ACCOUNT_MESSAGE_ROUTE = "/v2/account/message";
